import {Button, Input, Modal} from "antd";
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
const { TextArea } = Input;
export default function AddInstructor({props}) {

  function handleClick() {
    document.getElementById('fileUpload').click();
  }
  const handleDeleteProfile = () => {
    props.setProfile('');
  };

  return <Modal
      visible={props.visible}
      title="Add Instructor"
      onOk={props.createInstructor}
      onCancel={props.handleCancel}
      footer={[
        <Button key="back"
                onClick={props.handleCancel}>
          Return
        </Button>,
        <Button type="primary"
                key="submit"
                className={'btn btn-success'}
                loading={props.loading}
                onClick={props.createInstructor}>
          Submit
        </Button>
      ]}>
    <div className="p-2">
      <span>Name</span>
      <Input placeholder="Input Instructor Name"
             value={props.name}
             onChange={(e) => props.setName(e.target.value)}/>
    </div>

    <div className="p-2">
      <span>Name(EN)</span>
      <Input placeholder="Input Instructor Name"
             value={props.nameEN}
             onChange={(e) => props.setNameEN(e.target.value)}/>
    </div>

    <div className="p-2">
      <span>Description</span>
      <TextArea placeholder="Add Description Here"
                autosize={{ minRows: 5, maxRows: 6 }}
                value={props.description}
                onChange={(e) => props.setDesc(e.target.value)}/>
    </div>

    <div className="py-3 px-2 d-flex flex-col">
      <label className={'w-100percent'}>
        Upload Profile
      </label>
      <div className={'w-100percent pt-1 relative'}>
        <button onClick={handleClick}>
          Choose File
        </button>
        <span className='ml-2'>{props.profile?.name || 'No file chosen'}</span>
        <input type="file"
               id='fileUpload'
               name='filename'
               value=''
               hidden
               accept="image/png, image/gif, image/jpeg"
               onChange={(e) => {
                 props.setProfile(e.target.files[0]);
               }}/>
               {props.profile && 
                    <DeleteBtn>
                      <CloseOutlined className='text-right pointer'  onClick={handleDeleteProfile} />
                    </DeleteBtn>
                       }
      </div>
    </div>
  </Modal>
}

const DeleteBtn = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  color: red;
`;
