import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {useEffect, useState} from "react";
import '../public/styles/style.css';
import {Button, notification, Pagination, Spin} from "antd";
import {APIs} from "../constants/api.constants";
import {gridOption, frameworkComponents, dateFormatter, dateFilterParams, courseDropdownTextGetter} from '../constants/ag-grid.constants';
import {ApiService} from "../services/ApiService";
import AddInstructor from "../custom-components/instructor-popups/add-instructor";
import EditInstructor from '../custom-components/instructor-popups/edit-instructor';
import DeleteInstructor from '../custom-components/instructor-popups/delete-instructor';
import { AuthenticationService } from '../services/AuthenticationService';
import Search from 'antd/lib/input/Search';
import SortingTypeOption from '../custom-components/filter-and-sorting-popovers/sorting-type-option';
import { instructorSortingConstant } from '../constants/sorting.constants';


export default function Instructor() {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisibility] = useState(false);
  const [data, setData] = useState([]);
  const [name, setName] = useState('');
  const [nameEN, setNameEN] = useState('');
  const [courses, setCourses] = useState([]);
  const [profile, setProfile] = useState(null);
  const [description, setDesc] = useState('');
  const [selectedInstructor, setInstructor] = useState({});
  const [imagePopupIsOpen, setImagePopupIsOpen] = useState(false);
  const [imageFile, setImage] = useState(null);
  const [currentImageUrl, setImageUrl] = useState('');
  const [loadingRows, setLoadingRows] = useState(false);
  const [isOpen, toggleEdit] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [numOfCourse, setNumOfCourse] = useState(null);
  const [checkBox1, setCheckBox1] = useState(false);
  const [checkBox2, setCheckBox2] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [totalInstructor, setTotalInstructor] = useState(null);
  const [filterValue,setFilterValue] = useState("");
  const [sort, setSort] = useState("idAscending")

  const columnDefs = [
    {
      headerName: 'ID',
      field: "id",
      minWidth: 90,
      editable: false,
    },
    {
      headerName: 'Instructor Name',
      field: "name",
      minWidth: 200,
      editable: false,
    },
    {
      headerName: 'Instructor Name(EN)',
      field: "name_en",
      minWidth: 200,
      editable: false,
    },
    {
      headerName: 'Description',
      field: "idiom",
      minWidth: 300,
      editable: false,
    },
    {
      field: "courseName",
      editable: false,
      autoHeight: true,
      cellStyle: {'white-space': 'pre-wrap'},
      filter: 'agTextColumnFilter',
      valueGetter: courseDropdownTextGetter,
      cellRenderer: 'dropDownCellRenderer',
      cellRendererParams: {
        dropDownData: {
          items: courses,
          type: 'course_name',
          selectField: 'course',
        },
        clicked: function(event) {
          setCourseForInstructor(event);
        }
      },
      minWidth: 300
    },
    {
      field: "Current Image",
      editable: false,
      cellRenderer: "imageCellRenderer",
      cellRendererParams: {
        field: 'profile_image',
      },
      minWidth: 150,
    },
    {
      headerName: 'Created Date',
      field: 'createDate',
      valueGetter: dateFormatter,
      minWidth: 200,
      editable: false,
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
    },
    {
      headerName: 'Updated Date',
      field: 'updateDate',
      valueGetter: dateFormatter,
      minWidth: 200,
      editable: false,
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
    },
    {
      field: "Edit",
      editable: false,
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        buttonText: "Edit",
        buttonClass: "primary",
        clicked: function (field) {
          setEditingData(field);
          setName(field.name)
          setNameEN(field.name_en)
          setDesc(field.idiom)
          setSelectedCourses(field.course?.map((item) => item.id));
          setImageUrl(field.profile_image || '');
          toggleEditPopup();
        },
      },
      minWidth: 100,
    },
    {
      headerName: 'Disable',
      field: "deletedAt",
      cellRenderer: "customSwitch",
      editable: false,
      sort: 'asc',
      sortIndex: 0,
      cellRendererParams: {
        param: 'deletedAt',
        clicked: function(field) {
          field.deletedAt
              ? restoreInstructor(field)
              : deleteInstructor(field);
        }
      },
      minWidth: 100,
    },
    {
      field: "Deleted",
      editable: false,
      cellRenderer: "btnCellRenderer",
      sortIndex: 0,
      cellRendererParams: {
        buttonText: "Delete",
        buttonClass: "primary",
        clicked: function (field) {
          setNumOfCourse(field.course.length)
          setDeleteId(field.id)
          setName(field.name)
          setNameEN(field.name_en)
          toggleDeletePopup()
        },
      },
      minWidth: 100,
    },
  ];

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useEffect(() => {
    if (AuthenticationService.isLoggedIn()) {
      getInstructors().then(() => {});
      getCourses().then(() => {});
    }
    setLoading(false);
  }, []);

  async function getCourses() {
    ApiService.getRequest(APIs.courses).then(
        (res)=> setCourses(res.data),
        (error) => notification['error']({message: error.message })
    );
  }

  async function getInstructors(
    page = 1,
    limit = 100,
    sorting = "idAscending",
    filterValue
  ) {
    const param = {
      limit: limit,
      page: page,
      filterValue: filterValue,
      sorting: sorting,
    };
    setLoadingRows(true);
    ApiService.getRequest(APIs.instructor + "/pagination", param).then(
      (res) => {
        setTotalInstructor(res.data.meta.totalCount);
        setData(res.data.data);
        setLoadingRows(false);
      },
      (error) => notification["error"]({ message: error.message })
    );
  }

  function createInstructor() {
    setLoading(true);
    const postData = {
      name: name,
      name_en: nameEN,
      idiom: description,
    };
    ApiService.postRequest(APIs.instructor, postData).then(
        (res) => {
          setData([...data, res.data]);
          uploadProfile(res.data, profile);
          setLoading(false);
          setVisibility(false);
          resetInputs();
        },
        (error) => {
          notification['error']({message: error.message })
          setLoading(false);
          setVisibility(false);
        }
    );
  }

  function uploadProfile(instructor, file) {
    if (!file) {
      editInstructor({
        data: {...instructor, profile_image: ''},
      }); 
      return; 
   }
    const fd = new FormData();
    fd.append('file', file);
    ApiService.postRequest(APIs.courses + '/upload', fd).then(
        (res) => {
          editInstructor({
            data: {...instructor, profile_image: res.data},
          });
        },
        error => notification['error']({message: error.message })
    )
  }

  function editInstructor(event) {
    const newData = {
      name: event.data.name,
      name_en: event.data.name_en,
      idiom : event.data.idiom,
      profile_image: event.data.profile_image,
    }
    ApiService.updateRequest(APIs.instructor + '/' + event.data.id, newData).then(
      () => {
        getInstructors(page,limit,sort,filterValue).then(() => {});
        resetInputs();
        setImagePopupIsOpen(false);
      },
      (error) => notification['error']({message: error.message })
    );
  }

  function setCourseForInstructor(data) {
    const courseList = {
      id: data.id,
      course_list: data.items,
    };
    ApiService.postRequest(APIs.instructor + '/set-course', courseList).then(
        () => {},
        (error) => notification['error']({message: error.message })
    );
  }

  function deleteInstructor(event) {
    ApiService.deleteRequest(APIs.instructor + '/' + event.id).then(
      () => getInstructors(page,limit,sort,filterValue),
      (error) => notification['error']({message: error.message })
    );
  }

  function restoreInstructor(event) {
    ApiService.updateRequest(APIs.instructor + '/recover/' + event.id).then(
        () => getInstructors(page,limit,sort,filterValue),
        (error) => notification['error']({message: error.message })
    );
  }

  function uploadNewImage() {
    setLoading(true);
    uploadProfile(selectedInstructor, imageFile);
  }

  function toggleEditPopup() {
    isOpen && resetInputs();
    toggleEdit(!isOpen);
  }

  function resetInputs() {
    setName('');
    setNameEN('');
    setDesc('');
    setProfile(null);
    setImage(null);
    setLoading(false);
  }

  const showModal = () => {
    resetInputs();
    setVisibility(true);
  };

  const handleCancel = () => {
    setVisibility(false);
  };

  function toggleImagePopup() {
    !imagePopupIsOpen && resetInputs();
    setImagePopupIsOpen(!imagePopupIsOpen);
  }

  function resetDeleteInput() {
    setName('')
    setNameEN('')
    setDeleteId(null)
    setNumOfCourse(null)
    setCheckBox1(false)
    setCheckBox2(false)
  }

  function toggleDeletePopup() {
    deleteVisible && resetDeleteInput();
    setDeleteVisible(!deleteVisible);
  }

  function hardDelete() {
    if (checkBox1 !== true || checkBox2 !== true) {
      return notification["error"]({
        message: "please check all the checkbox to confirm deletion",
      });
    }
    setLoading(true);
    setLoadingRows(true);
    ApiService.deleteRequest(APIs.instructor + "/hard-delete/" + deleteId).then(
      () => {
        setLoading(false);
        toggleDeletePopup();
        getInstructors(page,limit,sort,filterValue);
      },
      (error) => {
        notification["error"]({ message: error.message });
        setLoading(false);
        toggleDeletePopup();
        setLoadingRows(false);
      }
    );
  }

  function submitEdit() {
    const newData = {
      ...editingData,
      name: name,
      name_en: nameEN,
      idiom: description,
      profile_image: currentImageUrl,
    };
    const newCourses = {
      id: editingData.id,
      items: selectedCourses,
    };
    setCourseForInstructor(newCourses);
    uploadProfile(newData, imageFile);
    editInstructor({ data: newData });
    toggleEditPopup()
  }

  const addInstructorProps = { visible, createInstructor, handleCancel, loading,
                                name, setName, description, setDesc, profile, setProfile, nameEN, setNameEN}

  const editInstructorProps = {isOpen, loading, toggleEditPopup, courses, setName, name, setNameEN, nameEN,
                                selectedCourses,setSelectedCourses, submitEdit, setImage, imageFile, currentImageUrl,
                                description, setDesc, setImageUrl}

  const deleteInstructorProps = {deleteVisible, toggleDeletePopup, name, nameEN, numOfCourse, setCheckBox1, checkBox1,
                                setCheckBox2, checkBox2, hardDelete, loading}

  return (
    <>
     <div className={"d-flex w-100percent row no-gutters"}>
       <div className={`col-md-5 pr-1 pb-3`}>
         <Search placeholder="input search text"
                 className={`w-100percent`}
                 allowClear
                 onSearch={(value) => {
                   setFilterValue(value);
                   getInstructors(page, limit, sort, value);
                 }}
                 prefix
                 enterButton>
         </Search>
       </div>
       <div className={`col-md-5 pb-3`}>
       <SortingTypeOption
            types={instructorSortingConstant}
            valueType={sort}
            changeFunction={(value) => {
              setSort(value);
              getInstructors(page, limit, value, filterValue);
            }}
          />
       </div>
       <div className="col-md-2 text-right pb-3">
         <Button type="primary" onClick={showModal}>
           Add New Row
         </Button>
       </div>
      </div>

      <AddInstructor props={addInstructorProps} />
      <EditInstructor props={editInstructorProps} />
      <DeleteInstructor props={deleteInstructorProps} />

      <Spin tip="Loading...." size="small" spinning={loadingRows}>
        <div className="example-wrapper h-minus-180">
          <div className="ag-theme-alpine  w-100percent h-100percent">
            <AgGridReact
              rowData={data}
              columnDefs={columnDefs}
              defaultColDef={gridOption}
              animateRows={true}
              enableCellTextSelection={true}
              onCellValueChanged={editInstructor}
              frameworkComponents={frameworkComponents}
              getRowNodeId={function (data) {
                return data.id;
              }}
              className={""}
              onGridReady={onGridReady}
            ></AgGridReact>
          </div>
        </div>
        <div className="d-flex w-100percent justify-content-end pt-2">
          <Pagination
            onChange={(page, limit) => {
              setPage(page);
              setLimit(limit);
              getInstructors(page, limit, sort, filterValue);
            }}
            showQuickJumper
            defaultCurrent={1}
            total={totalInstructor}
            pageSize={limit}
            pageSizeOptions={[50, 100, 500, 1000]}
          />
        </div>
      </Spin>
    </>
  );
}
