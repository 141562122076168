import { Button, Input, Image, Modal, Select } from "antd";
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea";

const Option = Select.Option;

const DeleteBtn = styled.div`
  position: absolute;
  left: 210px;
  top: 20px;
  color: red;
`;

export default function EditInstructor({ props }) {
  function imageClick() {
    document.getElementById("image").click();
  }

  const handleDeleteThumnail = () => {
    props.setImage("");
    props.setImageUrl("");
  };

  return (
    <Modal
      visible={props.isOpen}
      title="Edit Instructor"
      onOk={props.submitEdit}
      onCancel={props.toggleEditPopup}
      footer={[
        <Button key="back" onClick={props.toggleEditPopup}>
          Return
        </Button>,
        <Button
          type="primary"
          key="submit"
          className={"btn btn-success"}
          loading={props.loading}
          onClick={props.submitEdit}
        >
          Submit
        </Button>,
      ]}
    >
      <div className="p-2">
        <span>Instructor Name</span>
        <Input
          placeholder="Edit Instructor Name"
          value={props.name}
          onChange={(e) => props.setName(e.target.value)}
        />
      </div>

      <div className="p-2">
        <span>Instructor Name (EN)</span>
        <Input
          placeholder="Instructor Name(EN)"
          value={props.nameEN}
          onChange={(e) => props.setNameEN(e.target.value)}
        />
      </div>

      <div className="p-2">
      <span>Description</span>
      <TextArea placeholder="Add Description Here"
                autosize={{ minRows: 5, maxRows: 6 }}
                value={props.description}
                onChange={(e) => props.setDesc(e.target.value)}/>
    </div>

      <div className="p-2">
        <span>Courses</span>
        <Select
          showSearch
          value={props.selectedCourses}
          onChange={(e) => {
            props.setSelectedCourses(e);
          }}
          size="default"
          mode="multiple"
          placeholder={"Select Courses"}
          filterOption={(input, option) =>
            option?.props?.children
              ?.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          className="w-100percent ant-input new-flex mw-150"
        >
          {props.courses?.map((mode, index) => (
            <Option value={mode.id} key={index}>
              {mode.course_name}
            </Option>
          ))}
        </Select>
      </div>

      <div className="p-2">
        <label className={"w-100percent"}>Upload Profile</label>
        {!props.imageFile && props.currentImageUrl && (
          <div style={{ position: "relative" }}>
            <DeleteBtn>
              {" "}
              <CloseOutlined
                className="text-right pointer"
                onClick={handleDeleteThumnail}
              />
            </DeleteBtn>
            <div className={`w-100percent pt-1 mb-3`}>
              <Image src={props.currentImageUrl || ""} width={200} />
            </div>
          </div>
        )}

        <div className={"w-100percent pt-1"}>
          <button onClick={imageClick}>Choose File</button>
          <span className="ml-2">
            {props.imageFile?.name || "No file chosen"}
          </span>
          <input
            type="file"
            id="image"
            name="filename"
            value=""
            hidden
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => {
              props.setImage(e.target.files[0]);
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
