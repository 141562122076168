import { Button, Checkbox, Modal } from "antd";

export default function DeleteInstructor({ props }) {
  return (
    <Modal
      visible={props.deleteVisible}
      title="Delete this instructor?"
      onCancel={props.toggleDeletePopup}
      footer={[
        <Button key="Back" onClick={props.toggleDeletePopup}>
          Return
        </Button>,
        <Button
          type="danger"
          key="Delete"
          className={"btn btn-success"}
          loading={props.loading}
          onClick={props.hardDelete}
        >
          Permanent Delete
        </Button>,
      ]}
    >
      <div className="p-2">
        {props.name} ({props.nameEN})
      </div>

      <div className="p-2-small-text">
        <ul>
          <li>
            <p>
              instructor of {props.numOfCourse} course
            </p>
          </li>
        </ul>
      </div>

      <div className="p-2">
        <strong>Check all</strong> the items below to confirm instructor
        deletion, Deleting a instructor is permanent and <strong>cannot</strong> be undone
      </div>

      <div className="frame-detail">
        <div>
          <Checkbox
            onChange={(value) => {
              props.setCheckBox1(value.target.checked);
            }}
            checked={props.checkBox1}
          >
            Any future access of the data will be impossible
          </Checkbox>
        </div>

        <div>
          <Checkbox
            onChange={(value) => {
              props.setCheckBox2(value.target.checked);
            }}
            checked={props.checkBox2}
          >
            All records data of this instructor will be deleted
          </Checkbox>
        </div>
      </div>
    </Modal>
  );
}
